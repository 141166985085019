/* .swal2-select {
    padding: 10px;
    margin: 10px 0;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.swal2-select:invalid {
    border-color: red;
} */

.select-styled {
  padding: 0;
  margin: 20.25px 40.5px 3px;
  width: 261.46px !important;
  height: 51.14px !important;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  font-size: 16px;
  /* Adjust font size as needed */
  /* Adjust the height as needed */
  line-height: 1.5;
}

.select-styled:focus {
  border-color: #007bff;
  /* Change the border color on focus */
}

/* This is styles from form article */
/* Add this CSS to your stylesheet or create a new stylesheet */
#article-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

#article-form .form-group {
  margin-bottom: 1rem;
}

#article-form input[type="text"],
#article-form input[type="file"] {
  width: 100%;
  padding: 0.5rem;
}

#article-form textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

#article-form textarea {
  height: 100px;
  resize: vertical;
}

#article-form label {
  margin-bottom: 0.5rem;
  display: block;
  font-weight: bold;
  color: #333;
}

.ck-content ul,
.ck-content ol {
  padding-left: 40px; /* Pastikan ada padding untuk list */
}

.ck-content h1,
.ck-content h2,
.ck-content h3,
.ck-content h4,
.ck-content h5,
.ck-content h6 {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.ck-content p {
  margin: 10px 0;
}

.ck-content li {
  margin-bottom: 5px;
}

blockquote {
  font-style: italic;
  margin: 10px 20px;
  padding-left: 20px;
  border-left: 3px solid #ccc;
}
